import { isValid } from 'clients/Clients';
import MonitorClient from "clients/MonitorClient";
import {
    SCREEN_TO_ENUM_TRACKING_SOURCE_MAP,
    SOURCE_TO_ENUM_TRACKING_SOURCE_MAP,
} from "constants/data";
import { ENUM_TRACKING_SOURCE, MONITORING_COLLECTOR_TYPE } from "constants/Enums";

const convertProduct = (product) => {
    const { sellerInfo = {}, sku = "", productId = "" } = product;
    const { sellerID = "" } = sellerInfo || {};

    return {
        sku,
        seller_id: sellerID.toString(),
        product_id: productId.toString(),
    };
};

export const mapSourceToEnum = (product = {}, path = "") => {
    if (product?.isSameIngredient) return ENUM_TRACKING_SOURCE.SAME_INGREDIENT;
    if (product?.isSameCategoryProduct) return ENUM_TRACKING_SOURCE.SAME_CATEGORY;
    if (product?.isRecommended) return ENUM_TRACKING_SOURCE.RECOMMENDATION;
    if (product?.isRecommendedByTS) return ENUM_TRACKING_SOURCE.RECOMMENDATION_TS;
    // if (product?.isExclusive) return ENUM_TRACKING_SOURCE.EXCLUSIVE; // temporary hide, wait to create new source tracking for this product line

    return SOURCE_TO_ENUM_TRACKING_SOURCE_MAP[path] || "";
};

export const mapScreenToEnum = (product = {}, path = "") =>
    // if (product?.isSameCategoryProduct) return ENUM_TRACKING_SOURCE.SAME_CATEGORY;
    // if (product?.isSameIngredient) return ENUM_TRACKING_SOURCE.SAME_INGREDIENT;

    SCREEN_TO_ENUM_TRACKING_SOURCE_MAP[path] || "";

// document: https://buymed.atlassian.net/wiki/spaces/V2U/pages/549847093/collector
// TODO: use path -> source :  quick-order, product-list, promotion, seller-home, recommendation, same-category, whislist
const sendSKUEvent = async (event, product, path = "") => {
    const convertedProduct = convertProduct(product);
    const metadata = {
        ...convertedProduct,
        source: mapSourceToEnum(product, path),
        screen: mapScreenToEnum(product, path),
    };

    if (!path) delete metadata?.source;

    if (event === MONITORING_COLLECTOR_TYPE.SKU_IMPRESSION) {
        const productSentArray = JSON.parse(
            localStorage?.getItem("collectorProductSentArray") || "[]"
        )?.filter((item) => Date.now() - item?.lastTimeSend < 60000);
        const productIndex = productSentArray?.findIndex(
            (prd) => prd?.productId === convertedProduct?.product_id
        );

        // Mỗi sản phẩm với một user chỉ gửi một lần/phút

        if (productIndex === -1) {
            const res = await MonitorClient.collectEvent(event, metadata);
            if (isValid(res)) {
                const info = {
                    lastTimeSend: Date.now(),
                    productId: convertedProduct?.product_id || "",
                };
                productSentArray.push(info);

                try {
                    localStorage.setItem(
                        "collectorProductSentArray",
                        JSON.stringify(productSentArray)
                    );
                } catch (error) {
                    window.localStorage.removeItem("collectorProductSentArray");
                }
            }
            return res;
        }
    }

    const res = await MonitorClient.collectEvent(event, metadata);
    return res;
};

const sendLoginEvent = async (event, metadata) => {
    const res = await MonitorClient.collectEvent(event, metadata);
    return res;
};

const MAP_PATHS = {
    "/cart": "CART_DETAIL",
    "/checkout": "CHECKOUT",
    "/quick-order": "QUICK_ORDER",
    "/sellers": "SELLER_LIST",
    "/seller-products/": "SELLER_PRODUCTS",
    "/seller/[slug]": "SELLER_HOME",
    "/seller/[slug]/products": "SELLER_PRODUCTS",
    "/discovery": "DISCOVERY",
    "/users/wishlist": "WISHLIST",
    "/user/wishlist": "WISHLIST",
    "/khuyenmai": "PROMOTION",
    "/promotions": "PROMOTION",
    "/promo-codes": "VOUCHER",
    "/deals": "DEALS",
    // '/products': 'PRODUCT_LIST',
    // '/product/': 'PRODUCT_DETAIL',
    // '/productviewed': 'PRODUCT_VIEWED',
    // '/users/referrals': 'REFERRAL',
    // '/my-account': 'MY_ACCOUNT',
    // '/users/loyalty_points': 'LOYALTY_POINTS',
    // '/my-order': 'MY_ORDER',
    // '/my-order/[slug]': 'ORDER_DETAIL',
    // '/manufacturers': 'MANUFACTURER',
    // '/': 'HOME',
};

const convertUrlToPathEvent = (url) => {
    if (MAP_PATHS[url]) {
        return MAP_PATHS[url];
    }
    // if (url.startsWith('/product/')) {
    //   return MAP_PATHS['/product/'];
    // }

    if (url?.startsWith("/seller-products/")) {
        return MAP_PATHS["/seller-products/"];
    }
    if (url?.startsWith("/seller/") || url?.startsWith("/flagship-store/")) {
        if (url?.endsWith("/list-product")) {
            return MAP_PATHS["/seller/[slug]/products"];
        }
        // seller/[slug]/[rewards / products ]
        return MAP_PATHS["/seller/[slug]"];
    }
    if (url?.startsWith("/manufacturers/")) {
        return MAP_PATHS["/manufacturers"];
    }

    return "";
};

const convertUrlToScreen = ({ url, product }) => {
    if (mapScreenToEnum(product, url)) {
        return mapScreenToEnum(product, url);
    }
    if (url?.startsWith("/seller-products/")) {
        return mapScreenToEnum(product, "/seller-products/[slug]");
    }
    if (url?.startsWith("/seller/") || url?.startsWith("/flagship-store/")) {
        return mapScreenToEnum(product, "/seller/[slug]");
    }
    return null;
};

const convertUrlToSource = ({ url, product }) => {
    if (mapSourceToEnum(product, url)) {
        return mapSourceToEnum(product, url);
    }
    if (url?.startsWith("/seller-products/")) {
        return mapSourceToEnum(product, "/seller-products/[slug]");
    }
    if (url?.startsWith("/seller/") || url?.startsWith("/flagship-store/")) {
        return mapSourceToEnum(product, "/seller/[slug]");
    }
    return null;
};

const sendPageEvent = async ({ url, reffererUrl, product }) => {
    const event = convertUrlToPathEvent(url);
    if (event) {
        const metaData = {};
        const screen = convertUrlToScreen({ product, url: reffererUrl });
        if (screen) {
            metaData.screen = screen;
        }
        const source = convertUrlToSource({ product, url: reffererUrl });
        if (source) {
            metaData.source = source;
        }

        MonitorClient.collectEvent(event, metaData);
    }
};

const sendLivestreamEvent = async (event, metadata) => {
    // eslint-disable-next-line no-param-reassign
    metadata = {
      ...metadata,
      // screen: mapScreenToEnum({}, path),
      host: window?.location?.host || '',
    };
    const res = await MonitorClient.collectEvent(event, metadata);
    return res;
  };

export default {
    sendSKUEvent,
    sendLoginEvent,
    sendPageEvent,
    sendLivestreamEvent
};
