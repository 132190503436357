import "@fortawesome/fontawesome-free/css/all.min.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import Theme from "components/layout/Theme";
import { MOBILE } from "constants/Device";
import { ACCOUNT, CART_URL, CHECKOUT_URL, DOMAINS_THUOCSI, QUICK_ORDER } from "constants/Paths";
import { DEFAULT_THUOCSI_LONG_TITLE } from "constants/data";
import { AuthProvider, LoadingRoute } from "context/Auth";
import ContextProviderComposer from "context/ContextProviderComposer";
import { ProductContextProvider } from "context/Product/context";
import { SettingProvider } from "context/Settings";
import { TicketProvider } from "context/Ticket";
import { appWithTranslation } from "next-i18next";
import App from "next/app";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider as StyledTheme } from "styled-components";
import { DOMAIN_WEB_HOST, ENV } from "sysconfig";
import MonitorUtils from "utils/MonitorUtils";
import ScrollToTop from "utils/backToTop";
import gtag, { GA_TRACKING_ID_V2 } from "utils/gtag";
import { GLOBAL_STORE } from "zustand-lib";
import useMobileV2 from "zustand-lib/storeMobile";
import "../styles/globals.css";
import "../styles/icomoon.css";
import { SearchProvider } from "context/Search";

const { publicRuntimeConfig } = getConfig();

const pathNoRedirect = [
    "/",
    "/list-product",
    "",
    "/flagship-store",
    "/flagship-store/[slug]/list-product",
    "/flagship-store/[slug]",
    "/flagship-store/durex",
    "/flagship-store/durex/list-product",
    "/flagship-store/durex/rewards",
    "/flagship-store/sanofi",
    "/flagship-store/sanofi/list-product",
    "/flagship-store/sanofi/rewards",
    "/phanhoi",
    "/qr",
    "/qr/[code]",
    "phanhoi",
    "feedback",
    "/feedback",
    "/flagship-store/[slug]/flagship-rewards",
    "/flagship-store/[slug]/rewards",
    "/tracking-order",
    "/maintain",
];

const CHAT_HIDE_URLS = ["/discovery", CART_URL, CHECKOUT_URL, ACCOUNT, QUICK_ORDER];

const DynamicIconChat = dynamic(() => import("components-v2/atoms/IconChat"), {
    ssr: false,
});

const MyApp = (props) => {
    const { Component, pageProps, host = ""  } = props;

    const {
        user = {},
        SEO_CONFIG = {},
        initialZustandState = {},
    } = pageProps || {};

    const { useCreateStore, Provider } = GLOBAL_STORE || {};

    // Sentry?.setTag('buildId', buildId);
    // Sentry.setUser({ id: user?.customerID });
    const {
        thumbnailMap = {},
        defaultThumbnail = {},
    } = initialZustandState || {};

    const router = useRouter();
    const { query, pathname } = router || {};
    const reffererUrl = useRef("");
    const createStore = useCreateStore({ ...pageProps.initialZustandState, user });
    const { referCode, action, login, forgetpasscode, t, token, redirectUrl } = query || {};
    const isShowingLogin = login === "true";
    const isMobileV2 = useMobileV2((state) => state.isMobileV2());
    const isTrackingMobileV2 = useMobileV2((state) => state.isTrackingMobileV2);

    // Dùng SEO default là thông tin SEO của trang chủ
    const seoInfo = thumbnailMap[router.asPath] || {};

    // hide page chat
    const isShowIconFBPage = !CHAT_HIDE_URLS.includes(pathname);

    // config https://material-ui.com/guides/server-rendering/
    useEffect(() => {
        // if (window) window.Services = Service;

        // initAllSellers();
        // Remove the server-side injected CSS.
        // if (!isPrd && window) {
        //   window.Services = Services;
        // }
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        // lỗi zalo
        // if (!window.zaloJSV2) {
        //   window.zaloJSV2 = {};
        // }

        // nếu khách vào thuocsi.com.vn -> cần redirect lại domain chính
        if (window && window?.location?.href?.indexOf("thuocsi.com.vn") >= 0) {
            router.push(`${DOMAIN_WEB_HOST}`);
        }

        // Prevent pull to refresh for chat mobile
        if (router.pathname === "/conversations") {
            document.querySelector("html")?.classList.add("preventPullToRefresh");
        } else {
            document.querySelector("html")?.classList?.remove("preventPullToRefresh");
        }
    }, []);

    // DOMAINS_THUOCSI -> domain thuocsi sẽ chạy, các host khác proxy tới thuocsi sẽ bị đá lại domain chính
    useEffect(() => {
        // nếu chuyển sang trang products ... thì sẽ check host , nếu ko đúng host thì tự redirect sang domain chính
        if (DOMAINS_THUOCSI?.indexOf(host) === -1 && !pathNoRedirect.includes(router.pathname)) {
            router.push(`${DOMAIN_WEB_HOST}${router.asPath}`);
        }
    }, [router.asPath]);

    useEffect(() => {
        const handleRouteChange = async (url) => {
            gtag.pageview(url);
            // send evnt collector page view - thuannc 04Apr2023
            MonitorUtils.sendPageEvent({ url, reffererUrl: reffererUrl.current });
            reffererUrl.current = url;
            // fbpixel.pageview();
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="Cache-Control" content="no-cache" />
                <meta httpEquiv="Expires" content="-1" />
                <meta name="keywords" content="Chat với thuocsi" />
                <title>
                    {seoInfo?.pageTitle ||
                        SEO_CONFIG?.title ||
                        defaultThumbnail?.pageTitle ||
                        DEFAULT_THUOCSI_LONG_TITLE}
                </title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
                />
                <link rel="manifest" href="/manifest.json" />
            </Head>
            {/* <NextNprogress color="#29D" startPosition={0.3} stopDelayMs={200} height="3" options={{ showSpinner: false }} /> */}
            <StylesProvider injectFirst>
                <StyledTheme theme={Theme}>
                    <MuiThemeProvider theme={Theme}>
                        <CssBaseline />
                        {/* Context composer */}
                        <Provider createStore={createStore}>
                            <ContextProviderComposer
                                contextProviders={[
                                    <AuthProvider
                                        isShowingLogin={isShowingLogin}
                                        referCode={referCode}
                                        forgetpasscode={forgetpasscode}
                                        action={action}
                                        tokenv1={t}
                                        token={token}
                                        redirectUrl={redirectUrl}
                                        // initAccount={accountInfo}
                                        initUser={user}
                                    />,
                                    <ProductContextProvider initUser={user} />,
                                    <SearchProvider />,
                                    <SettingProvider />,
                                    <TicketProvider />,
                                ]}
                            >
                                {/* Protect route */}
                                <LoadingRoute>
                                    <Component {...pageProps} />
                                    {pageProps.isMobile &&
                                        isShowIconFBPage &&
                                        pathname === "/conversations" && (
                                            <DynamicIconChat
                                                isAuthenticated={pageProps?.isAuthenticated}
                                            />
                                        )}
                                    {(pathname !== "/conversations" && pathname !== "/video") && <ScrollToTop {...router} />}
                                    <ToastContainer
                                        limit={2}
                                        pauseOnHover={false}
                                        hideProgressBar
                                        autoClose={2000}
                                        closeOnClick
                                    />
                                </LoadingRoute>
                            </ContextProviderComposer>
                        </Provider>
                    </MuiThemeProvider>
                </StyledTheme>
            </StylesProvider>

            {ENV === "prd" && isMobileV2 && isTrackingMobileV2 && (
                <>
                    <Script
                        strategy="lazyOnload"
                        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID_V2}&l=dataLayerGAV2`}
                    />
                    <Script strategy="lazyOnload">
                        {`
                    window.dataLayerGAV2 = window.dataLayerGAV2 || [];
                    function gtagGAV2(){dataLayerGAV2.push(arguments);}
                    gtagGAV2('js', new Date());
                    gtagGAV2('config', '${GA_TRACKING_ID_V2}', {
                    page_path: window.location.pathname,
                    });
                `}
                    </Script>
                </>
            )}
        </>
    );
};

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

// MyApp.getInitialProps = async (appContext) => ({ ...(await App.getInitialProps(appContext)) });

MyApp.getInitialProps = async (appContext) => {
    const time = +new Date();
    const appProps = await App.getInitialProps(appContext);
    let host = "";
    try {
        host = appContext.ctx.req.headers.host || "";
    } catch (error) {
        host = "can't detect host";
    }

    let isMobile = "";
    try {
        const UA = appContext.ctx.req.headers["user-agent"];
        isMobile = Boolean(UA.match(MOBILE));
    } catch (error) {
        isMobile = `can not detect device - ${error}`;
    }

    return {
        ...appProps,
        host,
        buildId: publicRuntimeConfig.buildId,
        timeAPP: +new Date() - time,
        pageProps: {
            isMobile: !!isMobile,
        },
    };
};

export default appWithTranslation(MyApp);
