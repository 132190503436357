import { getData } from "clients/Clients";
import SellerClient from "clients/SellerClient";
import SupplierClient from "clients/SupplierClient";
import { BRAND_NAME } from "constants/Enums";
import { useAuth } from "context/Auth";
import { useLocalStorage } from "hooks/useLocalStorage";
import { createContext, useContext, useEffect, useState } from "react";
import { DOMAIN_FLAGSHIP_STORE } from "sysconfig";
import { convertArrayToMap } from "utils/ArrUtils";

const SettingContext = createContext({});

export const SettingProvider = ({ children }) => {
    const { isAuthenticated } = useAuth();
 
    // Seller info
    const [mapSeller, setMapSeller] = useState(new Map());
    const [sellers, getSellers] = useLocalStorage({
        key: "seller",
        getValueDefault: async () => {
            // get api mocks (gỉam data trả ra)
            const sellerRes = await SupplierClient.getSellersMock();
            const data = getData(sellerRes);
            return data && data.length > 0 ? data : null;
        },
        fieldNames: [
            "code",
            "isInternal",
            "name",
            "sellerType",
            "slug",
            "landingPage",
            "fullName",
            "isVip",
            "avatar",
            "status",
            "statusStore",
            "storeSlug",
            "isStore",
        ],
    });

    useEffect(() => {
        if (sellers && sellers.length > 0) {
            setMapSeller(convertArrayToMap(sellers, "code"));
        }
    }, [sellers]);

    // end sellers

    // store active

    const [mapStoreActives, setMapStoreActives] = useState(new Map());
    const [storeActives, getStoreActives] = useLocalStorage({
        key: "storeActives",
        getValueDefault: async () => {
            // get api mocks (gỉam data trả ra)
            const storeActiveResult = await SellerClient.getAllStoreActiveWeb({});
            const data = getData(storeActiveResult)?.map(({ sellerCode }) => ({ sellerCode }));
            return data && data.length > 0 ? data : null;
        },
        fieldNames: ["sellerCode"],
    });

    useEffect(() => {
        if (storeActives && storeActives.length > 0) {
            setMapStoreActives(convertArrayToMap(storeActives, "sellerCode"));
        }
    }, [storeActives]);

    // ENd COUNTRY

    useEffect(() => { 
        if (isAuthenticated) {
            if (!storeActives) {
                getStoreActives();
            }
            if (!sellers) {
                getSellers();
            }
        }
    }, [isAuthenticated]);

    // seller name
    const getNameSeller = (props) => {
        const { seller = {}, tags = [] } = props;
        const { code } = seller;
        const isVAT = tags?.indexOf("HOADONNHANH") >= 0 || false;
        const sellerInfo = mapSeller?.get(code) || null;

        let sellerName = "";
        let isDisplayStore = false;
        let fullNameSeller = "";
        const { isVip = false, slug = "" } = sellerInfo || {};
        let linkSeller = sellerInfo?.landingPage?.startsWith("https://try.thuocsi.vn")
            ? sellerInfo?.landingPage
            : `/seller-products/${sellerInfo?.slugStore || sellerInfo?.slug}`;
        let linkStore = `/seller/${slug}`;
        if (isVip) {
            linkStore =
                DOMAIN_FLAGSHIP_STORE.replace("{seller}", slug) || `/flagship-store/${slug}`;
        }

        // nếu không có sellerinfo => hiện rỗng
        if (sellerInfo) {
            if (sellerInfo.isInternal) {
                isDisplayStore = true;
                fullNameSeller = "MEDX";
                if (isVAT) {
                    sellerName = "MEDX";
                }
            } else if (sellerInfo?.sellerType === "ENTERPRISE") {
                isDisplayStore = true;
                sellerName = sellerInfo?.name || "";
                fullNameSeller = sellerInfo?.fullName || "";
            } else {
                sellerName = `Đối Tác Của ${BRAND_NAME}`;
                isDisplayStore = false;
                fullNameSeller = `Đối Tác Của ${BRAND_NAME}`;
                linkSeller = `/doitac/${code}`;
            }
        }
        return { sellerName, linkStore, linkSeller, isDisplayStore, fullNameSeller, ...sellerInfo };
    };

    return (
        <SettingContext.Provider
            value={{
                mapStoreActives,
                getNameSeller,
            }}
        >
            {children}
        </SettingContext.Provider>
    );
};

export const useSetting = () => useContext(SettingContext);

export default {
    SettingContext,
    useSetting,
};

