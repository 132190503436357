export function isEmpty(item) {
    return Object.keys(item) === 0;
}

export function groupArrayOfObjects(list, key) {
    return list.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

