// TODO: refactor contanst
import { DOMAIN_TS } from "sysconfig";
import { encodeUrl } from "utils/StringUtils";
/* eslint-disable operator-linebreak */
// WEB
export const MY_ACCOUNT = `${DOMAIN_TS}/my-account`;
export const MY_ORDER_URL = `${DOMAIN_TS}/my-order`;
export const ABOUT_US_URL = `${DOMAIN_TS}/about-us`;
export const PRIVACY_POLICY_URL = `${DOMAIN_TS}/privacy-policy`;
export const GENERAL_POLICY_URL = `${DOMAIN_TS}/general-policy`;
export const CONDITIONS_OF_USE_URL = `${DOMAIN_TS}/conditions-of-use`;
export const DISPUTE_RESOLUTION_URL = `${DOMAIN_TS}/dispute-resolution`;
export const TERMS_URL = `${DOMAIN_TS}/terms-and-condition`;
export const QUY_CHE_HOAT_DONG_URL = `${DOMAIN_TS}/static/quy-che-hoat-dong/quy-che-hoat-dong.pdf`;
export const CHINH_SACH_BAO_MAT_URL = `${DOMAIN_TS}/chinh-sach-bao-mat`;
export const CHINH_SACH_GIAI_QUYET_KHIEU_NAI_URL = "/chinh-sach-giai-quyet-khieu-nai";
export const REGULATIONS_URL = `${DOMAIN_TS}/regulations`;
export const NOTIFICATIONS = `${DOMAIN_TS}/notifications`;
export const QUICK_ORDER = "/quick-order";
export const CHINH_SACH_DANG_TAI_SAN_PHAM_URL = `${DOMAIN_TS}/chinh-sach-dang-tai-san-pham`;
export const PRODUCT = `${DOMAIN_TS}/product/[slug]`;
export const PRODUCT_LIST = "/products";
export const DEALS = `${DOMAIN_TS}/deals`;
export const PRODUCTS_URL = `${DOMAIN_TS}/products`;
export const PRODUCTS_LOADING_URL = `${DOMAIN_TS}/products/loading`;
export const CART_URL = `${DOMAIN_TS}/cart`;
export const CART_LOADING_URL = `${DOMAIN_TS}/cart/loading`;
export const SUPPLIER = `${DOMAIN_TS}/supplier`;
export const INGREDIENT = `${DOMAIN_TS}/ingredients`;
export const MANUFACTURERS = `${DOMAIN_TS}/manufacturers`;
export const CHECKOUT_URL = `${DOMAIN_TS}/checkout`;
export const THANKYOU_URL = `${DOMAIN_TS}/thankyou`;
export const NOT_FOUND_URL = `${DOMAIN_TS}/404`;
export const PROMO_CODES = `${DOMAIN_TS}/promo-codes`;
export const HOME_PAGE = `${DOMAIN_TS}/`;
export const CATEGORIES = `${DOMAIN_TS}/categories`;
export const USER_PROMO_CODES_URL = `${DOMAIN_TS}/users/my-voucher`;
export const KHUYEN_MAI = `${DOMAIN_TS}/khuyenmai`;
export const KHUYEN_MAI_LOADING = `${DOMAIN_TS}/khuyenmai/loading`;
export const SELLERS = `${DOMAIN_TS}/sellers`;
export const SELLERS_LOADING_URL = `${DOMAIN_TS}/sellers/loading`;
export const HOW_TO_UPLOAD_PRODUCTS = `${DOMAIN_TS}/huong-dan-dang-tai-san-pham`;
export const HOW_TO_ORDER_AND_PAYMENT = `${DOMAIN_TS}/huong-dan-dat-hang-va-thanh-toan`;
export const HOW_TO_RETURN_REFUND = `${DOMAIN_TS}/chinh-sach-doi-tra`;
export const PRODUCT_DETAILS_LOADING_URL = `${DOMAIN_TS}/product/loading`;
export const REFERRALS_PAGE = `${DOMAIN_TS}/users/referrals`;
export const LOYALTY_PAGE = `${DOMAIN_TS}/users/loyalty_points`;
export const WISHLIST = `${DOMAIN_TS}/user/wishlist`;
export const BULK_ORDER = `${DOMAIN_TS}/bulk-order/import`;
export const BULK_ORDER_CART = `${DOMAIN_TS}/bulk-order/cart`;
export const BULK_ORDER_LIST = `${DOMAIN_TS}/bulk-order`;
export const DISCOVERY = `${DOMAIN_TS}/discovery`;
export const ACCOUNT = `${DOMAIN_TS}/users/account`;
export const MY_TICKET = `${DOMAIN_TS}/users/my-ticket`;
export const CONVERSATIONS = `${DOMAIN_TS}/conversations`;
export const EXCHANGE_LOYALTY = `${DOMAIN_TS}/users/exchange-loyalty`;
export const MY_VOUCHER_PAGE = `${DOMAIN_TS}/users/my-voucher`;
export const NEW_PRODUCTS_URL = `${DOMAIN_TS}/sanphammoi`;
export const TAG_PAGE = `${DOMAIN_TS}/tag`;

export const getPathProductBySlug = (slug) => `${PRODUCT.replace("[slug]", encodeUrl(slug))}`;
export const getPathProductBySlugWithDomain = (slug) =>  getPathProductBySlug(slug);

export const getPathOrderById = (id) => `${`${MY_ORDER_URL}/${id}`}`;
export const getPathOrderByIdWithDomain = (id) =>  getPathOrderById(id);

export const getPathTicketById = (id) => `${`${MY_TICKET}?id=${id}`}`;
export const getPathTicketByIdWithDomain = (id) =>  getPathTicketById(id);

// SUB DOMAIN
export const PATH_NEWS = "https://news.thuocsi.com.vn";
export const PATH_CAREER = "https://career.thuocsi.com.vn";
export const PATH_SUPPLIER = "https://supplier.thuocsi.vn";
export const CAREER = "https://career.thuocsi.com.vn/";
export const HELP_CENTER = "https://phanhoi.thuocsi.vn";
export const THUOCSI_SUPPORT = "https://thuocsihotro.helpwise.help/";
export const THUOCSI_SUPPORT_SELLER = "https://thuocsisellercenter.helpwise.help/";
export const PATH_TS_FACEBOOK = "https://www.facebook.com/thuocsivn/";
export const PATH_TS_ZALO = "https://zalo.me/1836616064223034882";
export const PATH_TS_LINKED = "https://www.linkedin.com/company/thuocsivn/";
export const PATH_TS_TIKTOK = "https://www.tiktok.com/@thuocsi.vietnam?";
export const PATH_SHIPPING_POLICY = "/shipping-policy";
// OUTBOUND

export const PATH_INFO_BILL = "https://thuocsihotro.helpwise.help";
export const QNA = "https://thuocsihotro.helpwise.help/";
export const ORDER_GUIDE = "https://thuocsihotro.helpwise.help/";
export const LICENSE_PDF =
    "https://buymed-storage.s3-ap-southeast-1.amazonaws.com/trading_license/1.+Trading+License+-+Buymed+(GC+20+June+2019)+(VN).pdf";
export const LEGAL_IMAGE =
    "https://assets.thuocsi.vn/assets/bct-150ac1809a7ae41e0a4b21f1e1e21a26a2f93ee0c79e5c99cc197dd2fdc988c8.png";
export const FEEDBACK = "https://cs.stg.thuocsi.vn/feedback";

export const NEW_PRODUCT = "https://try.thuocsi.vn/daitiechangmoi";

export const WEB = {};

export const ASK_PRODUCT =
    "https://forms.office.com/Pages/ResponsePage.aspx?id=f-ffA68NfkimxyYB5SgTPbGuKhViJltLlJQcOW8IoQpURVY2VU1MTjU1M01LSVdIS0hDSUIzWkZTWC4u";

// DOMAINS_THUOCSI -> domain thuocsi sẽ chạy, các host khác proxy tới thuocsi sẽ bị đá lại domain chính
export const DOMAINS_THUOCSI = [
    "v2.thuocsi.vn",
    "mienbac.thuocsi.vn",
    "thuocsi.vn",
    "thuocsi.com.vn",
    "thuocsi.net",
    "web.v2-stg.thuocsi.vn",
    "web.v2-dev.thuocsi.vn",
    "web.v2-uat.thuocsi.vn",
    "local.thuocsi-web.vn",
    "local-prd.thuocsi-web.vn",
    "local.thuocsi.vn",
    "hk.thuocsi.vn",
    "sg.thuocsi.vn",
    "cambodia-stg.thuocsi.vn",
    "cambodia-dev.thuocsi.vn",
    "cambodia-uat.thuocsi.vn",
    "buymed.com.kh",
    "maintain.thuocsi.vn",
    "maintain.v2-stg.thuocsi.vn",
    "localhost:3000",
    "10.200.230.136:3000",
    "chat.v2-stg.thuocsi.vn",
    "chat.v2-dev.thuocsi.vn",
    "chat.v2-uat.thuocsi.vn",
    "chat.thuocsi.vn",
    process.env.NEXT_PUBLIC_CHAT_HOST || "",
];

export const BOTTOM_NAVIGATION_PAGES = [
    "/",
    DISCOVERY,
    SELLERS,
    SELLERS_LOADING_URL,
    KHUYEN_MAI,
    KHUYEN_MAI_LOADING,
];
export const ROUTES_CAN_PUSH = ["/sellers?type=all"]; // list các routes có thể vào stack routing bỏ qua check duplicate
export const MOBILE_SHOW_LOGO_AND_SWITCH_PAGES = ["/"];
export const MOBILE_SHOW_BACK_BUTTON_PAGES = [
    CART_URL,
    CHECKOUT_URL,
    QUICK_ORDER,
    "/thankyou/[id]",
    MY_ORDER_URL,
    "/user/dashboard",
    "/tracking-order",
    "/productviewed",
    "/users/referrals",
    USER_PROMO_CODES_URL,
    "/user/wishlist",
    "/qr",
    "/users/my-ticket",
    "/users/loyalty_points",
    "/users/rewards",
    "/khuyenmai/[slug]",
    PRODUCT,
    PRODUCTS_URL,
    "/product/[slug]/loading",
    PROMO_CODES,
    "/seller/[slug]",
    "/flagship-store/sanofi",
    "/flagship-store/durex",
    "/seller/[slug]/list-product",
    "/sellers?type=flagship",
    "/sellers?type=all",
    "/flagship-store/[slug]",
    "/flagship-store/[slug]/list-product",
    "/flagship-store/[slug]/flagship-rewards",
];

export const DEFAULT_THUMBNAIL_PATH = "/default";
